<template>
  <discord-invite />
</template>

<script>
 import DiscordInvite from '../components/discordInvite';

export default {
  name: 'Widget',
  components: {
    DiscordInvite
  }
}
</script>
